<template>
  <div class="login__container">
    <img
      class="login__image-2"
      :src="require('@/assets/images/login/woman-riding-bicycle.svg')"
    />
    <img
      class="login__image-3"
      :src="require('@/assets/images/login/windfarm.svg')"
    />
    <Content align="center">
      <img
        class="login__logo"
        :src="require('@/assets/images/logos/bupa/bupa-square.svg')"
      />
    </Content>
    <Content align="center">
      <FlexContainer>
        <FlexItem v-show="mode === 'login'" size="66.6%" class="login__content">
          <img
            class="login__image-1"
            :src="require('@/assets/images/login/man-with-cape.svg')"
          />
          <article class="login__text">
            <h2>Small actions, <span>Big impact.</span></h2>
            <h3>Contribute to the greater good</h3>
          </article>
        </FlexItem>
        <FlexItem size="33.3%">
          <Login />
        </FlexItem>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Login from '@/components/login/Login'

export default {
  components: {
    Login
  },
  data: function() {
    return {
      mode: null
    }
  },
  computed: {
    ...mapGetters({
      isSignedIn: 'user/isSignedIn'
    })
  },
  mounted() {
    if (this.isSignedIn) {
      this.$router.push('/')
    }
    this.mode = this.$route.path.split('/')[1]
  }
}
</script>

<style lang="scss">
body {
  background: #f7f8f3;
}

.login__logo {
  display: block;
  margin: auto;
  padding-top: $spacing-m;
  width: 80px;

  @include respond-above(md) {
    padding-top: 5rem;
    padding-bottom: 10rem;
    margin: 0;
    width: 120px;
  }
}

.login__container {
  position: relative;
  width: 100%;

  @include respond-above(md) {
    padding-bottom: $padding-largest;
  }
}

.login__image-1 {
  position: absolute;
  z-index: 0;
  right: 0;
  display: block;
  width: 100%;

  @include respond-above(md) {
    width: auto;
  }
}

.login__image-2 {
  display: none;
  position: absolute;
  left: 0;
  top: 40%;
  transform: translateX(-50%);

  @include respond-above(md) {
    display: block;
  }
}

.login__image-3 {
  display: none;
  position: absolute;
  top: 45%;
  right: 0;

  @include respond-above(md) {
    display: block;
  }
}

.login__form {
  position: relative;
}

.login__content {
  position: relative;
  min-height: 400px;
}

.login__text {
  position: relative;
  z-index: 1;

  h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
    color: $color-gray--dark;
    margin: $spacing-xxxl 0;

    span {
      display: block;
      font-weight: 700;
      font-size: 57px;
      line-height: 0.8;
    }
  }

  h3 {
    display: none;
    font-size: 36px;
    line-height: 1.5;
    margin: $spacing-l 0;
  }

  @include respond-above(md) {
    h2 {
      font-size: 60px;
      line-height: 1;

      span {
        display: block;
        font-size: 119px;
        line-height: 0.8;
      }
    }

    h3 {
      display: block;
    }
  }
}
</style>
