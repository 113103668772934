<template>
  <div class="login">
    <div v-if="isSignedIn">
      <p>Welcome {{ currentUser.name }}. Your registration is now complete.</p>
      <p>
        Before you can access the app, we need to verify your account. We will
        contact you when your account has been enabled.
      </p>
      <ButtonLink type="tertiary" full-width="true" @click.native="signOut">
        Logout
      </ButtonLink>
    </div>
    <div v-if="mode === 'login'" class="login__form">
      <transition name="fade">
        <section v-show="step === 'email'" class="login__step">
          <Field label="Email">
            <EmailInput
              v-model.trim="form.login.email"
              name="email"
              error-msg="Please enter your email"
              @keyup.enter="step = 'password'"
            />
          </Field>
          <Field v-if="error" no-label="true">
            <Alert type="error">{{ error }}</Alert>
          </Field>
          <Field no-label="true">
            <Button
              type="secondary"
              full-width="true"
              :disabled="!form.login.email"
              @click.native="step = 'password'"
            >
              Next
            </Button>
          </Field>
        </section>
      </transition>
      <transition name="fade">
        <section v-show="step === 'password'" class="login__step">
          <Field label="Password">
            <PasswordInput
              v-model="form.login.password"
              name="password"
              error-msg="Please enter your password"
            />
          </Field>
          <Field v-if="error" no-label="true">
            <Alert type="error">{{ error }}</Alert>
          </Field>
          <Field no-label="true">
            <ButtonLink
              type="secondary"
              full-width="true"
              @click.native="signIn"
            >
              Log in
            </ButtonLink>
          </Field>
        </section>
      </transition>

      <Field no-label="true">
        <ButtonRouterLink full-width="true" to="/sign-up">
          Create account
        </ButtonRouterLink>
        <router-link class="forgot-password" to="/forgot-password">
          Trouble logging in?
        </router-link>
      </Field>
    </div>
    <div v-if="!isSignedIn && mode === 'sign-up'" class="login__form">
      <form autocomplete="off">
        <Field label="Email">
          <EmailInput
            v-model.trim="form.signUp.email"
            name="email"
            placeholder="Enter your email"
            autocomplete="off"
            error-msg="Please enter your email"
          />
        </Field>
        <Field label="Name">
          <TextInput
            v-model.trim="form.signUp.name"
            name="name"
            placeholder="Enter your name"
            autocomplete="off"
            error-msg="Please enter your name"
          />
        </Field>
        <Field label="Password">
          <PasswordInput
            v-model="form.signUp.password"
            name="password"
            placeholder="Enter your password"
            autocomplete="false"
            error-msg="Please enter your password"
          />
        </Field>
        <Field label="Department">
          <SelectInput
            v-model="form.signUp.department"
            :options="departmentOptions"
            class="switch-reasons"
          />
        </Field>
        <Field v-if="error" no-label="true">
          <Alert type="error">{{ error }}</Alert>
        </Field>
        <Field no-label="true">
          <ButtonLink type="secondary" full-width="true" @click.native="signUp">
            Create account
          </ButtonLink>
        </Field>
        <p class="forgot-password">Already have an account?</p>
        <Field no-label="true">
          <ButtonRouterLink type="primary" full-width="true" to="/login">
            Login
          </ButtonRouterLink>
        </Field>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  data: function() {
    return {
      mode: 'login',
      step: 'email',
      departmentOptions: [
        {
          text: 'Admin',
          value: 'admin'
        },
        {
          text: 'Board / Director',
          value: 'director'
        },
        {
          text: 'CSR',
          value: 'csr'
        },
        {
          text: 'Customer Service',
          value: 'customer-service'
        },
        {
          text: 'Distribution',
          value: 'distribution'
        },
        {
          text: 'Finance',
          value: 'finance'
        },
        {
          text: 'HR',
          value: 'hr'
        },
        {
          text: 'ICT',
          value: 'ict'
        },
        {
          text: 'Marketing',
          value: 'marketing'
        },
        {
          text: 'Operations / Production',
          value: 'operations'
        },
        {
          text: 'Procurement',
          value: 'procurement'
        },
        {
          text: 'R&D',
          value: 'rnd'
        },
        {
          text: 'Sales',
          value: 'sales'
        }
      ],
      form: {
        login: {
          email: null,
          password: null
        },
        signUp: {
          email: null,
          name: null,
          password: null,
          department: null
        }
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser,
      error: state => state.user.error
    }),
    ...mapGetters({
      isSignedIn: 'user/isSignedIn'
    })
  },

  watch: {
    error: {
      handler() {
        this.step = 'email'
      }
    }
  },
  mounted() {
    this.mode = this.$route.path.split('/')[1]
    this.step = 'email'
    this.clearErrors()
  },
  methods: {
    ...mapActions({
      createUser: 'user/createUser',
      emailPasswordSignIn: 'user/emailPasswordSignIn',
      googleSignIn: 'user/googleSignIn',
      signOut: 'user/signOut',
      clearErrors: 'user/clearErrors'
    }),
    signUp() {
      this.createUser(this.form.signUp)
    },
    signIn() {
      this.emailPasswordSignIn({
        email: this.form.login.email,
        password: this.form.login.password
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  color: $color-black;

  &__form {
    width: 100%;
  }

  &__step {
    margin-bottom: $spacing-m;
  }

  &__google-signin {
    display: block;
    margin: auto;
    height: 48px;

    &:hover {
      cursor: pointer;
    }
  }

  .forgot-password {
    margin-top: 10px;
    margin-bottom: 10px;
    color: $color-primary;
    display: block;
    font-weight: 500;
    text-align: center;
  }
}
</style>
